import Footer from '@/components/Footer';
import NavBar from '@/components/NavBar';
import { FeatureFlags } from '@/helpers/props';
import { useTranslations } from 'next-intl';
import Head from 'next/head';
import { ReactNode } from 'react';

type MainLayoutProps = {
  featureFlags: FeatureFlags;
  children: ReactNode;
};
const MainLayout = ({ featureFlags, children }: MainLayoutProps) => {
  const t = useTranslations('common');
  return (
    <div className="flex flex-col h-screen">
      <Head>
        <title>{t('magentacicd')}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <NavBar featureFlags={featureFlags} />
      <main className={`p-10 container mx-auto flex-grow`}>{children}</main>
      <Footer />
    </div>
  );
};

export default MainLayout;
