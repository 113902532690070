import type { DCSession } from '@/types/auth';
import { Session } from 'next-auth';

export const getRoles = (session: Session | null): string[] => {
  if (session) {
    const customSession = session as unknown as DCSession;
    if (customSession.roles.length > 0) return customSession.roles;
  }
  return [];
};

export const isAdmin = (session: Session | null): boolean => {
  return getRoles(session).includes('admin');
};

export const isSupport = (session: Session | null): boolean => {
  return getRoles(session).includes('supporter');
};

export const isSupportOrAdmin = (session: Session | null): boolean => {
  return isSupport(session) || isAdmin(session);
};

export const getAccessToken = (session: Session | null): string => {
  if (sessionUsable(session)) {
    const customSession = session as unknown as DCSession;
    return customSession.accessToken;
  }
  return '';
};

export const sessionUsable = (session: Session | null): boolean => {
  if (!session) {
    return false;
  }
  return (
    (session as unknown as DCSession).eternal ||
    (session as unknown as DCSession).accessTokenExpires > Date.now()
  );
};
