import { isSupportOrAdmin } from '@/helpers/auth';
import { FeatureFlags } from '@/helpers/props';
import { ApiError } from '@/types/commandBlock';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useLocale, useTranslations } from 'next-intl';
import { useTheme } from 'next-themes';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import {
  TbLanguage,
  TbLogin,
  TbLogout,
  TbMenu2,
  TbMoon,
  TbSun,
  TbUser,
} from 'react-icons/tb';

type MenuItem = {
  text: string;
  shown: boolean;
} & (
  | {
      href: string;
      sub?: undefined;
    }
  | {
      href?: undefined;
      sub: Omit<MenuItem, 'sub' | 'shown'>[];
    }
);

const NavBar = ({ featureFlags }: { featureFlags: FeatureFlags }) => {
  const t = useTranslations('common');
  const locale = useLocale();
  const router = useRouter();
  const { data: session } = useSession();

  const { theme, setTheme } = useTheme();

  function changeLanguage(locale: string) {
    router.push(
      {
        pathname: router.pathname,
        query: router.query,
      },
      router.asPath,
      { locale },
    );
  }

  useEffect(() => {
    if (!session) return;

    if ((session as unknown as ApiError).error === 'RefreshAccessTokenError') {
      signIn('keycloak'); // Force sign in to hopefully resolve error
    }
  }, [session]);

  const authenticated = !!session;
  const admin = isSupportOrAdmin(session);

  // TODO: implement shown for sub items
  const menu: MenuItem[] = [
    {
      text: t('self-services'),
      href: '/selfservices',
      shown: featureFlags.selfservices,
    },
    {
      text: t('recertifications'),
      href: '/recertifications',
      shown: authenticated && admin,
    },
    {
      text: t('groups'),
      shown: authenticated,
      sub: [
        {
          text: t('groups-all'),
          href: '/groups',
        },
      ],
    },
    {
      text: t('service-accounts'),
      shown: authenticated,
      sub: [
        {
          text: t('service-accounts-all'),
          href: '/serviceaccounts',
        },
        {
          text: t('service-account-new'),
          href: '/serviceaccounts/new',
        },
      ],
    },
    {
      text: t('admin'),
      shown: authenticated && admin,
      sub: [
        {
          text: t('billing-types'),
          href: '/billing-types',
        },
        {
          text: t('data-export'),
          href: '/export',
        },
        {
          text: t('jobs'),
          href: '/jobs',
        },
        {
          text: t('organisations'),
          href: '/organisations',
        },
        {
          text: t('users'),
          href: '/users',
        },
        {
          text: t('group-new'),
          href: '/groups/orders/new',
        },
      ],
    },
  ];

  const menuFiltered = menu.filter((item) => item.shown);

  const LANGUAGES = [
    { locale: 'en', text: 'EN', long: 'English' },
    { locale: 'de', text: 'DE', long: 'Deutsch' },
  ];

  return (
    <div className="bg-primary">
      <div className="navbar xl:container xl:mx-auto text-white">
        <div className="navbar-start">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost lg:hidden">
              <TbMenu2 size={24} />
            </label>
            <ul
              tabIndex={0}
              className="menu dropdown-content bg-base-100 dark:bg-base-200 text-base-content rounded-box z-[2] min-w-64 p-2 shadow dark:shadow-telekom-gray"
            >
              {menuFiltered.map((item, index) => (
                <li key={index}>
                  {item.href ? (
                    <Link
                      key={item.text}
                      href={item.href!}
                      locale={router.locale}
                    >
                      {item.text}
                    </Link>
                  ) : (
                    <span className="menu-title">{item.text}</span>
                  )}

                  {item.sub && (
                    <ul>
                      {item.sub.map((subItem, subIndex) => (
                        <li key={`${index}-${subIndex}`}>
                          <Link
                            key={subItem.text}
                            href={subItem.href!}
                            locale={router.locale}
                          >
                            {subItem.text}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <Link className="btn btn-ghost" href="/">
            <Image
              className="w-20 h-10"
              src="/T_logo.svg"
              width={80}
              height={40}
              alt="Telekom MIS Portal"
            />
          </Link>
        </div>
        <div className="navbar-center hidden lg:flex">
          {menuFiltered.map((item, index) => {
            if (item.sub) {
              return (
                <div key={index} className="dropdown dropdown-hover">
                  <label tabIndex={0} className="btn btn-ghost">
                    {item.text}
                  </label>
                  <ul
                    tabIndex={0}
                    className="menu dropdown-content bg-base-100 dark:bg-base-200 text-base-content rounded-box z-[2] min-w-64 p-2 shadow dark:shadow-telekom-gray"
                  >
                    {item.sub?.map((subitem) => (
                      <li key={subitem.text}>
                        <Link
                          key={subitem.text}
                          href={subitem.href!}
                          locale={router.locale}
                        >
                          {subitem.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            } else {
              return (
                <Link
                  key={index}
                  className="btn btn-ghost"
                  href={item.href}
                  locale={router.locale}
                >
                  {item.text}
                </Link>
              );
            }
          })}
        </div>
        <div className="navbar-end">
          {session && (
            <div className="dropdown dropdown-hover dropdown-end">
              <div
                tabIndex={0}
                role="button"
                className="btn btn-ghost rounded-btn"
              >
                <TbUser size={20} aria-hidden /> {session?.user?.name}
              </div>
              <ul
                tabIndex={0}
                className="menu dropdown-content bg-base-100 dark:bg-base-200 text-base-content rounded-box z-[2] min-w-48 p-2 shadow dark:shadow-telekom-gray"
              >
                <li>
                  <a onClick={() => signOut()}>
                    <TbLogout size={18} aria-hidden /> {t('logout')}
                  </a>
                </li>
              </ul>
            </div>
          )}
          {!session && (
            <button
              className="btn btn-ghost join-item"
              onClick={() => signIn('keycloak')}
            >
              <TbLogin size={20} aria-hidden /> {t('login')}
            </button>
          )}

          <div className="dropdown dropdown-hover dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost rounded-btn"
            >
              <TbLanguage size={20} aria-hidden />{' '}
              {LANGUAGES.find((item) => item.locale === locale)?.text || 'EN'}
            </div>
            <ul
              tabIndex={0}
              className="menu dropdown-content bg-base-100 dark:bg-base-200 text-base-content rounded-box z-[2] min-w-48 p-2 shadow dark:shadow-telekom-gray"
            >
              {LANGUAGES.map((item) => (
                <li key={item.locale}>
                  <a onClick={() => changeLanguage(item.locale)}>{item.long}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="indicator">
            <span className="indicator-item badge badge-xs">beta</span>
            <label className="swap swap-rotate">
              {/* this hidden checkbox controls the state */}
              <input
                type="checkbox"
                className="theme-controller"
                defaultChecked={theme === 'light'}
                value={theme}
                onClick={() => {
                  if (theme === 'dark') setTheme('light');
                  else setTheme('dark');
                }}
              />
              <TbSun className="swap-off size-6 fill-current" />
              <TbMoon className="swap-on size-6 fill-current" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
